import "../App.css"
import spriteSquire from "../assets/spriteSquire.webp"
import spriteChemist from "../assets/spriteChemist.webp"
import spriteKnight from "../assets/spriteKnight.webp"
import spriteLancer from "../assets/spriteLancer.webp"
import spriteSummoner from "../assets/spriteSummoner.webp"
import spriteBlackMage from "../assets/spriteBlackMage.webp"
import spriteRamza1 from "../assets/spriteRamza1.webp"
import spriteRamza2 from "../assets/spriteRamza2.webp"
import spriteRamza3 from "../assets/spriteRamza3.webp"
import spriteAgrias from "../assets/spriteAgrias.webp"
import spriteOrlandeu from "../assets/spriteOrlandeu.webp"
import spriteMeliadoul from "../assets/spriteMeliadoul.webp"


export default function Roadmap(){
return (<>
<div className="Roadmap">
<div className="RoadmapCardConteiner">

<div className="CardRoadmap">
    <h1>Q2 - 2024</h1>
    <div>
        <img src={spriteSquire} className="CardSpriteRoadmap"/>
        <img src={spriteRamza1} className="CardSpriteRoadmap"/>
        <img src={spriteChemist} className="CardSpriteRoadmap"/>
    </div>
    
    <h2>Project Development</h2>

    <div className="ListCardRoadmap">
        <p>Token ($WARCHOCOBO) and All Jobs NFT Collection Launch</p>
        <p>Website Launch</p>
        <p>Marketing Campaign Kickoff</p>
        <p>Project Planning and Roadmap Creation</p>
    </div>
</div>

<div className="CardRoadmap">
    <h1>Q3 - 2024</h1>
    <div>
        <img src={spriteKnight} className="CardSpriteRoadmap"/>
        <img src={spriteRamza2} className="CardSpriteRoadmap"/>
        <img src={spriteBlackMage} className="CardSpriteRoadmap"/>
    </div>
    
    <h2>Game Development + NFT Launch</h2>

    <div className="ListCardRoadmap">
        <p>Characters NFT Collection Launch</p>
        <p>$WARCHOCOBO Lissting Campaign</p>
        <p>Marketing Campaign Expansion</p>
    </div>
</div>

<div className="CardRoadmap">
    <h1>Q4 - 2024</h1>
    <div>
        <img src={spriteLancer} className="CardSpriteRoadmap"/>
        <img src={spriteRamza3} className="CardSpriteRoadmap"/>
        <img src={spriteSummoner} className="CardSpriteRoadmap"/>
    </div>

    <h2>Game Launch</h2>
    <div className="ListCardRoadmap">
        <p>Game Marketing</p>
        <p>Tournament Planning and Execution</p>
        <p>Marketing Campaign Intensification</p>
        <p>Community Growth Initiatives</p>
    </div>
</div>

<div className="CardRoadmap">
    <h1>Q1 - 2025</h1>
    <div>
        <img src={spriteMeliadoul} className="CardSpriteRoadmap"/>
        <img src={spriteAgrias} className="CardSpriteRoadmap"/>
        <img src={spriteOrlandeu} className="CardSpriteRoadmap"/>
    </div>

    <h2>Research and Development</h2>
    <div className="ListCardRoadmap">
        <p>Implementation of DeFi principles and mechanisms</p>
        <p>Integration of decentralized governance mechanisms</p>
        <p>Exploration of cross-game interoperability</p>
    </div>
</div>


</div>

</div>
</>)   
}
