import "../App.css"
import spriteSquire from "../assets/spriteSquire.webp"
import spriteChemist from "../assets/spriteChemist.webp"
import spriteKnight from "../assets/spriteKnight.webp"
import spriteLancer from "../assets/spriteLancer.webp"
import spriteSummoner from "../assets/spriteSummoner.webp"
import spriteBlackMage from "../assets/spriteBlackMage.webp"
import spriteRamza1 from "../assets/spriteRamza1.webp"
import spriteRamza2 from "../assets/spriteRamza2.webp"
import spriteRamza3 from "../assets/spriteRamza3.webp"
import spriteAgrias from "../assets/spriteAgrias.webp"
import spriteOrlandeu from "../assets/spriteOrlandeu.webp"
import spriteMeliadoul from "../assets/spriteMeliadoul.webp"


export default function ProjectOverviewSection(){
return (<>
<div className="Roadmap">
<div className="RoadmapCardConteiner">

<div className="ProjectOverviewCard">
    <h1>NFT Collections</h1>

    <li>Introduction</li>
    <p>
    Welcome to our NFT Collection, inspired by Final Fantasy Tactics. Our collection combines stunning artwork with rarity, evolving over time with advancements in DeFi technology.
    </p>
    <li>Collection Features</li>
    <p>Explore breathtaking artwork capturing the essence of Final Fantasy Tactics' universe.</p>
    <p>Discover NFTs of varying rarities, each with unique traits and abilities.</p>
    <li>In-Game Applications</li>
    <p>Use NFTs in gameplay, with future features like cross-game compatibility and play-to-earn mechanics.</p>
    <p>Engage in battles, trading, and alliances within our vibrant community</p>
    


</div>

<div className="ProjectOverviewCard">
    <h1>$WARCHOCOBO Token</h1>

    <li>Introduction</li>
    <p>
    $WARCHOCOBO, the cornerstone of our decentralized ecosystem. Built on DeFi principles, it embodies innovation, accessibility, and community empowerment.
    </p>
    <li>Token Functions</li>
    <p>Acquire in-game items and stake tokens for rewards and influence.</p>
    <p>Benefit from tokenomics, including burning mechanisms and protocol upgrades.</p>
    <li>Advantages</li>
    <p>Experience ownership in a growing ecosystem with steady enhancements and partnerships.</p>
    <p>Participate in governance initiatives, shaping the project's future.</p>
</div>

<div className="ProjectOverviewCard">
    <h1>NFT Card Game</h1>

    <li>Introduction</li>
    <p>
    Enter a strategic duel with our NFT Card Game, inspired by Final Fantasy Tactics. It offers deck-building, strategy, and PvP combat, all powered by DeFi principles.
    </p>
    <li>Collection Features</li>
    <p>Build decks with NFT cards, ensuring fairness and transparency.</p>
    <p>Engage in thrilling PvP matches with decentralized matchmaking.</p>
    <li>Rewards and Progression</li>
    <p>Rise through competitive ladders, earning rewards through yield farming and NFT staking.</p>
    <p>Experience progression with each battle won, unlocking new strategies and assets.</p>
    


</div>

</div>
</div>
</>)   
}
