import "../App.css"
import ConnectButton from '../components/ConnectButton'

export default function Navbar(){
return (<>
<div className="Navbar">


<div className="NavbarItem">
<h3><a href="#">Home</a></h3>

</div>

<div className="NavbarItem">
<h3><a href="#ProjectOverview">Project Overview</a></h3>
</div>

<div className="NavbarItem">
<h3><a href="#Roadmap">Roadmap</a></h3>
</div>

<div className="NavbarItem">
<h3><a href="#NFTLaunch">NFT Launch</a></h3>
</div>

<div className="NavbarItem">
<h3><a href="#TokenLaunch">Token Launch</a></h3>
</div>


</div>
</>)   
}
