import "../App.css"
import UnorderedHand from '../assets/UnorderedHandRemoveBg.png'
import chillAllJobs from "../assets/chillLivingRoomAllJobs.jpg"

const style = {}


export default function NFTCardsCarouselAllJobs(){
return (<>
<div className="shadowBackground">
<div className="whiteBorderWithGlow">
<div className="flexLayout">

<img className="NFTCollections-AllJobs-Img" src={chillAllJobs}/>


    <div className="columnCenterLayout">
    <div className="bigTitle"><h2> All Jobs Classes Collection</h2></div>
    
    <img className="NFTCollections-AllJobs-ImgSmall" src={UnorderedHand}/>
    <div className="HeroUnderText">
        <h2> 22 Jobs Classes Battle Cards released for sale!</h2>
    </div>
    <button className="HeroButton" onClick={function (){window.open('https://opensea.io/collection/ivalicememoriesnftcardscollection', '_blank')}}> Buy at Opensea </button>
    </div>


</div>
</div>
</div>

</>)   
}
