import './App.css';

import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Roadmap from './components/Roadmap';
import TokenSection from './components/TokenSection';
import TokenLaunchTittle from './components/TokenLaunchTittle';
import NFTLaunchTittle from './components/NFTLaunchTittle';
import AllJobsNFTCollections from './components/AllJobsNFTCollections'
import ProjectOverviewTittle from './components/ProjectOverviewTittle';
import ProjectOverviewSection from './components/ProjectOverviewSection';
import RoadmapTittle from './components/RoadmapTittle';
import Footer from './components/Footer';

import { motion } from 'framer-motion';

function App() {
return (<>
    <motion.div>
    <Navbar/>
    </motion.div>
    
    <motion.div
          initial={{opacity:0.1}}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
          exit={{ opacity:0 }}
          >
    <Hero/>
    </motion.div>
    
    <motion.div
          initial={{opacity:0.1}}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
          exit={{ opacity:0 }}
          >
    <ProjectOverviewTittle/>
    <ProjectOverviewSection/>
    </motion.div>
    
    <motion.div
         initial={{opacity:0.1}}
         whileInView={{ opacity: 1 }}
         transition={{ duration: 1.5 }}
         exit={{ opacity:0 }}
         >
    <RoadmapTittle/>
    <Roadmap/>
    </motion.div>
    
    <motion.div
         initial={{opacity:0.1}}
         whileInView={{ opacity: 1 }}
         transition={{ duration: 1.5 }}
         exit={{ opacity:0 }}
         >
    <NFTLaunchTittle/>
    <AllJobsNFTCollections/>
    </motion.div>
    
    <motion.div
         initial={{opacity:0.1}}
         whileInView={{ opacity: 1 }}
         transition={{ duration: 1.5 }}
         exit={{ opacity:0 }}
         >
    <TokenLaunchTittle/>
    <TokenSection/>
    </motion.div>
    
    <motion.div 
          initial={{opacity:0}}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
          exit={{ opacity:0 }}
          >
    <Footer/>
    </motion.div> 
    
    </>)
}
export default App;
