import "../App.css"


export default function TokenLaunchTittle(){
return (<>
<div id="TokenLaunch">
<div className="Roadmap">
<div className="RoadmapTitle"> Token Launch </div>
</div>
</div>

</>)   
}
