import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const styles =  `
  body{
    margin: 0;
    margin: 0;
    padding: 0;
  }
`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<>
<style>{styles}</style>
<App/>
</>);
