import "../App.css"
import AllJobsRemoveBG from "../assets/AllJobs_ok-removebg-preview.png"
import Xlogo from "../assets/xlogoRemoved.png"
import Telegramlogo from '../assets/telegramlogo-removebg.png'
import Redditlogo from '../assets/redditlogo-removebg.png'

export default function Footer(){
return (<>
<div className="FooterContainer">

<div className="FooterContainerColumns">
    <div className="FooterColumns">
    <img className="FooterLogoImg" src={Xlogo} onClick={function (){window.open('https://twitter.com/IvaliceMemories', '_blank')}}/>
    <h4 onClick={function (){window.open('https://twitter.com/IvaliceMemories', '_blank')}}>X</h4>
    </div>

    <div className="FooterColumns">
    <img className="FooterLogoImg" src={Telegramlogo} onClick={function (){window.open('https://t.me/ivalicememories', '_blank')}}/>
    <h4 onClick={function (){window.open('https://t.me/ivalicememories', '_blank')}}>Telegram</h4>
    </div>
    
    <div className="FooterColumns">
    <img className="FooterLogoImg" src={Redditlogo} onClick={function (){window.open('https://www.reddit.com/user/Ivalice_Memories/', '_blank')}}/>
    <h4 onClick={function (){window.open('https://www.reddit.com/user/Ivalice_Memories/', '_blank')}}>Reddit</h4>
    </div>

</div>

<div className="FooterMiddleText">
<hr className="FooterLine"/>
<div>Join us in our Social Networks!</div>
</div>

</div>



<div className="FooterContainer">
<div className="FooterContainerImg">
<img className="FooterImg" src={AllJobsRemoveBG}/>
</div>
</div>

</>)   
}
