import "../App.css"
import ImgCard from "../assets/NFTCardsHandsRemove.png"

export default function Hero(){
    const ethereum = window.ethereum;
function show(){

    if(ethereum){
        ethereum.request({ method: "eth_requestAccounts" })
        .then((result) => {
            alert("Your wallet address: "+result[0])        
        })
        .catch((error) => {alert(error.message)})
    } else {
        alert("error with connection.")
    }


}

return (<>
<div className="Hero">
    <div>
    
    <h1>Remember these warriors from Ivalice ...</h1>
    <div className="HeroUnderText">
    <h1>Join the battle, collect NFTs brought back from Ivalice, earn rewards, remembering epic warriors from Final Fantasy Tactics®</h1>
    </div>
    <div className="HeroButtonBox">
    <button className="HeroButtonBig" onClick={function (){show()}}> Connect Metamask </button>
    </div>

    </div>
    
    <div>
        <img src={ImgCard}/>
    </div>
</div>
</>)   
}
