import "../App.css"
import React, { useState } from 'react';
import WarChocobo from "../assets/chocobo_coin-removebg.png"
import {ABI, TokenAddress} from "../contract/ABI.js"
import { Web3 } from 'web3';


export default function TokenSection(){

const web3 = new Web3(window.ethereum);
const ContractInitialized = new web3.eth.Contract(ABI, TokenAddress);

const [buyAmount, setBuyAmount] = useState('');
const handleBuyAmount = (event) => { setBuyAmount(event.target.value) }

const [stakeAmount, setStakeAmount] = useState('');
const handleStakeAmount = (event) => { setStakeAmount(event.target.value) }

const [currentAddress, setCurrentAddress] = useState('')
window.ethereum.request({ "method": "eth_accounts", })
.then((result) => { if(result[0]){setCurrentAddress(result[0])}else{setCurrentAddress(result[0])} })

const [stakedAmountInContract, setStakedAmountInContract] = useState('');
async function stakedAmountInContractFunction(){
    window.ethereum.request({ "method": "eth_accounts", })
    .then((address) => { if(address[0]){
        ContractInitialized.methods.staked1(address[0]).call()
        .then((result) => {
        let prevResult = parseInt(result)/10**18
        if(prevResult > 1){ setStakedAmountInContract(prevResult) }
        else{ setStakedAmountInContract(0) }
        })
    }
    })
    };

stakedAmountInContractFunction();

async function LaunchSalePhase1(amount){
    try {
        let toWeiResult = web3.utils.toWei(amount, 'ether');
        let _value = toWeiResult / 40;
        await ContractInitialized.methods.publicSale1().send({ from: currentAddress, value: _value });
        window.location.reload();
        alert("Tokens transferred successfully");
    } catch (error) {
        alert("Error transferring tokens: "+ error.message );       
    }
    };

async function stakePhase1(amount){
    try {
        await ContractInitialized.methods.stakePhase1(amount*10**18).send({ from: currentAddress });
        window.location.reload();
        alert("Tokens transferred successfully");
    } catch (error) {
        alert("Error transferring tokens:"+ error.message);
    }
    };

async function unstake1(){
    try {
        await ContractInitialized.methods.unstake1().send({ from: currentAddress });
        window.location.reload();
        alert("Tokens transferred successfully");
    } catch (error) {
        alert("Error transferring tokens:"+ error.message);
    }
    };

async function addWarchoboAsset(){
    await window.ethereum.request({
        "method": "wallet_watchAsset",
        "params": {
          "type": "ERC20",
          "options": {
            "address": "0x9F0B5e805A8E01ed19667255Ef5051104f433efc",
            "image": "https://salmon-neat-raccoon-933.mypinata.cloud/ipfs/QmURdp7NCHvK1RzxNm3wBmP4UvTNcdumvPfX73Lfv9sKg8/32x32-chocobo_coin.png"
          }
        }
      });
}

return (<>
<div className="shadowBackground">
<div className="whiteBorderWithGlow">
<div className="flexLayout">

<div className="bigTitle">
    <div className="columnCenterLayout">
    <h4> $WARCHOCOBO </h4>
    <img className="Warchocobo-Img" src={WarChocobo}/>
    <button className="HeroButton" onClick={function (){addWarchoboAsset()}}> Add $WARCHOCOBO </button>
    </div>
</div>

<div className="TokenomicsDetails">
<div className="columnCenterLayout">
    <h2> Tokenomics </h2>
<div className="TokenomicsRigthText">
<div>Total Supply : 500,000,000 $WARCHOCOBO</div>
<div>Devs : 8,500,000 $WARCHOCOBO</div>
<h3> Phase 1 </h3>
<div>Sat Jun 15 2024 03:00:00 GMT+0000</div>
<div>Public Sale : 125,000,000 $WARCHOCOBO</div>
<div>Staking Rewards : 62,500,000 $WARCHOCOBO</div>
<div>1 $WARCHOCOBO = 0.025 MATIC</div>

<h3> Phase 2 </h3>
<div>Sun Jul 14 2024 03:00:00 GMT+0000</div>
<div>Public Sale : 267,500,000 $WARCHOCOBO</div>
<div>Staking Rewards : 32,500,000 $WARCHOCOBO</div>
<div>1 $WARCHOCOBO = 0.075 MATIC</div>

</div>

</div>
</div>

<div className="TokenomicsDetails">

<h3> Phase 1 </h3>

<input className="TokenomicsRoundedInput" type="text" value={buyAmount} onChange={handleBuyAmount}/>
<button className="HeroButton" onClick={function (){LaunchSalePhase1(buyAmount)}}> Buy Tokens </button>
<br/>
<input className="TokenomicsRoundedInput" type="text" value={stakeAmount} onChange={handleStakeAmount}/>
<button className="HeroButton" onClick={function (){stakePhase1(stakeAmount)}}> Stake Tokens</button>
<br/>
<button className="HeroButton" onClick={function (){unstake1()}}> Unstake </button>
<div> Aviable after every phase´s end. </div>
<div> Staked Amount: {stakedAmountInContract} $WARCHOCOBO </div>

</div>

</div>
</div>
</div>

</>)   
}