import "../App.css"


export default function RoadmapTittle(){
return (<>
<div id="Roadmap">
<div className="Roadmap">
<div className="RoadmapTitle"> Roadmap </div>
</div>
</div>

</>)   
}
