import "../App.css"


export default function TokenLaunchTittle(){
return (<>
<div id="NFTLaunch">
<div className="Roadmap">
<div className="RoadmapTitle"> NFT Launch </div>
</div>
</div>

</>)   
}
